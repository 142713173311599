import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'HomeApp',
    component: () => import(/* webpackChunkName: "about" */ '../views/Home.vue')
  },
  {
    path: '/accueil',
    name: 'HelloWorld',
    component: () => import(/* webpackChunkName: "about" */ '../views/HelloWorld.vue')
  },
  {
    path: '/prestations',
    name: 'prestationsApp',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Prestations.vue')
  },
  
  {
    path: '/produits',
    name: 'produitsApp',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Produits.vue')
  },
  {
    path: '/offres',
    name: 'offresApp',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Offres.vue')
  },
  {
    path: '/contact',
    name: 'contactApp',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Contact.vue')
  },
  {
    path: '/prestations/vaccuum',
    name: 'Vaccuum',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Vaccuum.vue')
  },
  {
    path: '/prestations/maquillage',
    name: 'Maquillage',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Maquillage_tarifs.vue')
  },
  {
    path: '/prestations/nailart',
    name: 'Nails',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Ongles.vue')
  },
  {
    path: '/prestations/mains_pieds',
    name: 'Mains_pieds',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Mains_pieds.vue')
  },
  {
    path: '/prestations/visages',
    name: 'Visages',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Visages.vue')
  },
  {
    path: '/prestations/corps',
    name: 'Corps',
    // route level code-splitting
    component: () => import(/* webpackChunkName: "about" */ '../views/Corps.vue')
  },
 {
    path: '/prestations/cils',
    name: 'Cils',
    // route level code-splitting
        component: () => import(/* webpackChunkName: "about" */ '../views/Cils.vue')
     }

]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
