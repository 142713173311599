<template>

    <nav id="navbar">
            <router-link to="/accueil" title="Accueil">ACCUEIL</router-link> 
            <router-link to="/prestations" title="Prestations">PRESTATIONS</router-link>
            <router-link to="/produits" title="Mes produits">MES PRODUITS</router-link> 
            <router-link to="/offres" title="Offres">OFFRES</router-link> 
            <router-link to="/contact" title="Contact">CONTACT</router-link> 
    </nav>

    <router-view/>
</template>

<script>
export default {
    name: "NavBar",
    methods: {
        
        }
    }
</script>

<style scoped>
#navbar {
    display: flex;
  
margin:auto;
    justify-content: space-evenly;
    padding: 10px;
    font-weight:700;
margin-bottom:60px!important;
}
#navbar a{
    text-decoration: none;
    color: #a71412;
}
#navbar a:active{
    border-bottom: 5px solid #fbdee0;
}
@media screen and (min-width: 740px) and (max-width: 1024px) {
#navbar{
    width:50%;
	
}
}
@media screen and (max-width: 740px){
#navbar{
    width: 170px;
    justify-content: space-around;
    margin: auto;
    border-radius: 100%;
    flex-direction: column;
    padding: 15px;
    border-radius: 10px;
margin-left:90px;
}
#navbar a{
    margin: 10px;
    width: 150px;
}
#navbar a:hover{
    background: #fbdee0;
    border-radius: 10px;
    padding: 5px;

}

}
</style>
