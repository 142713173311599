<template>
<NavBar/>
<div id="app">
  
</div>

</template>

<script>
import NavBar from './components/NavBar.vue'
export default {
  name: 'App',
  components: {
    NavBar
  }
}
</script>

<style>
#app {
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif ;
  font-style: italic;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  width: 100%;
  margin-top: 60px;
}
@media screen and (min-width: 740px) and (max-width: 1024px) {
  #app{
    display: flex;
    flex-direction: column;
    width: 100%;
  }
    }
@media screen and (max-width: 740px){
  #app{
    width: 375px;
    margin: auto;
  }
}
</style>
